import React,{useState,useEffect} from 'react';
import { 
  makeStyles,
  Box,
  Typography
} from '@material-ui/core';
import {Tabs,Tab,AppBar} from '@material-ui/core';
import PropTypes from 'prop-types'; 
import Page from 'src/components/Page';  
import usePersistedState from 'src/utils/usePersistedState'; 
import EInvoiceDashboard from './EInvoiceDashboard'; 
import InvoiceDashboard from "./InvoiceDashboard";
import { API } from "aws-amplify";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  paper: {
    'margin-bottom': '10px' 
  }
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `profile-tab-${index}`,
    'aria-controls': `profile-tabpanel-${index}`,
  };
}
const Dashboard = (props) => {
  const classes = useStyles();
  console.log('Dashboard props',props);
  const [isLoading, setIsLoading] = useState(false); 
  const [value, setValue] = useState(0);
  const [invoiceMatrix, setInvoiceMatrix] = usePersistedState('invoiceMatrix',{
    "Processed":0,
    "Rejected":0,
    "Validation Failed":0,
    "Usage":0
  }); 
  const [eInvoiceMatrix, setEInvoiceMatrix] = usePersistedState('eInvoiceMatrix',{
    "Usage":0,
    "Rejected":0,
    "Validation Failed":0,
    "Validated":0
  }); 
  const [invoiceTrafficLine, setInvoiceTrafficLine] = usePersistedState('invoiceTrafficLine',{ 
  emailData:[],
  apiData:[],
  sftpData:[],
  label:[] }); 
  const [eInvoiceTrafficLine, setEInvoiceTrafficLine] = usePersistedState('eInvoiceTrafficLine',{
  emailData:[],
  apiData:[],
  sftpData:[],
  label:[] });
  const [invoiceTraffic, setInvoiceTraffic] = usePersistedState('invoiceTraffic',{
    "Email": 0,
    "Api": 0,
    "Sftp": 0,
    "total": 0
  }); 
    const [eInvoiceTraffic, setEInvoiceTraffic] = usePersistedState('eInvoiceTraffic',{
      "Email": 0,
      "Api": 0,
      "Sftp": 0,
      "total": 0
    });
  useEffect(() => {
    const dashboardMatrix = async () => {
     setIsLoading(true);
     await fetchEInvoiceMatrix();
     await fetchInvoiceMatrix();
     await fetchTrafficBySourceMatrixLine('Pdf Invoice');
     await fetchTrafficBySourceMatrixLine('Signed Invoice');
     await fetchTrafficBySourceMatrix('getTrafficByInvoiceSource');
     await fetchTrafficBySourceMatrix('getTrafficByEInvoiceSource');
     setIsLoading(false);
   } 
   dashboardMatrix()
   },[]);
   const fetchEInvoiceMatrix= async ()=>{
    try {
      let apiName = "OptiVIVeAPI";
      let path = "/module";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: { masterType: "User", UserID: props.userSession.UserName, tenantId:props.userSession.TenantId  },
        queryStringParameters: {
          module: "invoice",
          op: "getEInvoiceMatrix"
        }
      };
       var response=await API.post(apiName, path, myInit);
      await setEInvoiceMatrix(response.data ? response.data : {
        "Usage": 0,
        "Rejected": 0,
        "Validation Failed": 0,
        "Validated": 0
      }); 
    }
    catch (error) {
      console.log("set login time error",error); 
    } 
   }
   const fetchInvoiceMatrix= async ()=>{
    try {
      let apiName = "OptiVIVeAPI";
      let path = "/module";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: { masterType: "User", UserID: props.userSession.UserName, tenantId:props.userSession.TenantId  },
        queryStringParameters: {
          module: "invoice",
          op: "getInvoiceMatrix"
        }
      };
       var response=await API.post(apiName, path, myInit);
       await setInvoiceMatrix(response.data ? response.data : {
        "Processed":0,
        "Rejected":0,
        "Validation Failed":0,
        "Usage":0
      }); 
    }
    catch (error) {
      console.log("set login time error",error); 
    } 
   }
   const fetchTrafficBySourceMatrixLine = async (type) => {
    try {
      let apiName = "OptiVIVeAPI";
      let path = "/module";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: { type: type, UserID: props.userSession.UserName, tenantId: props.userSession.TenantId },
        queryStringParameters: {
          module: "invoice",
          op: 'getTrafficBySourceLine'
        }
      };
      var response = await API.post(apiName, path, myInit);
      if(type==='Pdf Invoice')
        await setInvoiceTrafficLine(response.data ?response.data:{ emailData:[], apiData:[], sftpData:[], label:[] });
      if(type==='Signed Invoice')
       await setEInvoiceTrafficLine(response.data ? response.data : { emailData:[], apiData:[], sftpData:[], label:[] }); 
    }
    catch (error) {
      console.log("set login time error", error);
    }
  }
  const fetchTrafficBySourceMatrix = async (op) => {
    try {
      let apiName = "OptiVIVeAPI";
      let path = "/module";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: { masterType: "User", UserID: props.userSession.UserName, tenantId: props.userSession.TenantId },
        queryStringParameters: {
          module: "invoice",
          op: op
        }
      };
      var response = await API.post(apiName, path, myInit);
      if(op==='getTrafficByInvoiceSource')
      await setInvoiceTraffic(response.data ? response.data : {
        "Email": 0,
        "Api": 0,
        "Sftp": 0,
        "total": 0
      });
      if(op==='getTrafficByEInvoiceSource')
      await setEInvoiceTraffic(response.data ? response.data : {
        "Email": 0,
        "Api": 0,
        "Sftp": 0,
        "total": 0
      }); 
    }
    catch (error) {
      console.log("set login time error", error);
    }
  }
   const handleChange = (event, newValue) => {     
    setValue(newValue);    
  };
  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >   
        <AppBar position="static" style={{ background: 'white' }}>
        <Tabs value={value} onChange={handleChange} aria-label="Profile tabs">
        <Tab style={{ color: '#2E3B55' }} label="Invoice" {...a11yProps(0)} />
          <Tab style={{ color: '#2E3B55' }} label="E-Invoice" {...a11yProps(1)} />          
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={1}> 
          <EInvoiceDashboard eInvoiceTrafficLine={eInvoiceTrafficLine} eInvoiceTraffic={eInvoiceTraffic} matrix={eInvoiceMatrix} userSession={props.userSession} /> 
      </TabPanel>
      <TabPanel value={value} index={0}>
        <InvoiceDashboard invoiceTrafficLine={invoiceTrafficLine}  invoiceTraffic={invoiceTraffic} matrix={invoiceMatrix} userSession={props.userSession} />
      </TabPanel>
    </Page>
  );
};

export default Dashboard;
