/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:764e3081-19fb-48d3-ab33-c72771ee8ae1",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_fJTKFr04a",
    "aws_user_pools_web_client_id": "7tslpijd80odjtmlqafhssir04",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "OptiVIVeAPI",
            "endpoint": "https://ba2stlwv09.execute-api.ap-south-1.amazonaws.com/app",
            "region": "ap-south-1"
        },
        {
            "name": "OptiVIVeMobileAPI",
            "endpoint": "https://acsmlbwgk4.execute-api.ap-south-1.amazonaws.com/app",
            "region": "ap-south-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "ap-south-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "optivive-app",
            "region": "ap-south-1"
        }
    ],
    "aws_user_files_s3_bucket": "optivive-store122732-app",
    "aws_user_files_s3_bucket_region": "ap-south-1",
    "aws_content_delivery_bucket": "dev.opti-vive.com-app",
    "aws_content_delivery_bucket_region": "ap-south-1",
    "aws_content_delivery_url": "https://dg3i853d27edz.cloudfront.net"
};


export default awsmobile;
