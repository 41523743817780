import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';  
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import CustomerListView from 'src/views/customer/CustomerListView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView'; 
import RegisterView from 'src/views/auth/RegisterView';
import VerifyView from 'src/views/auth/VerifyView';
import SettingsView from 'src/views/settings/SettingsView';  
import AllEInvoice from 'src/views/e-invoice/All'; 
import InvoiceRejected from 'src/views/invoice/Rejected';
import InvoiceProcessed from 'src/views/invoice/Processed';
import InvoiceFailed from 'src/views/invoice/Failed';
import AllInvoice from 'src/views/invoice/All'; 
import EInvoiceRejected from 'src/views/e-invoice/Rejected';
import EInvoiceValidated from 'src/views/e-invoice/Validated';
import EInvoiceFailed from 'src/views/e-invoice/Failed'; 
 
const routes =(isAuthenticated,userHasAuthenticated,userSession,setuserSession,props)=>{  
  return [ 
  {
    path: 'app',
    element: isAuthenticated && userSession.AccountType!=="ADMIN_MASTER"?<DashboardLayout isAuthenticated={isAuthenticated} userHasAuthenticated={userHasAuthenticated} userSession={userSession} setuserSession={setuserSession} props={props} />:<Navigate to="/login" />,
    children: [
      { path: 'account', element: <AccountView userSession={userSession} /> },
      { path: 'vendor', element: <CustomerListView userSession={userSession} /> },
      { path: 'dashboard', element: <DashboardView userSession={userSession}/> },      
      { path: 'settings', element: <SettingsView userSession={userSession} /> },  
      { path: 'e-invoice/all', element: <AllEInvoice userSession={userSession} /> },     
      { path: 'e-invoice/failed', element: <EInvoiceFailed userSession={userSession} /> },
      { path: 'e-invoice/validated', element: <EInvoiceValidated userSession={userSession} /> },
      { path: 'e-invoice/rejected', element: <EInvoiceRejected userSession={userSession} /> },
      { path: 'invoice/all', element: <AllInvoice userSession={userSession} /> },         
      { path: 'invoice/failed', element: <InvoiceFailed userSession={userSession} /> },
      { path: 'invoice/processed', element: <InvoiceProcessed userSession={userSession} /> },
      { path: 'invoice/rejected', element: <InvoiceRejected userSession={userSession} /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'trainer',
    element:<></>
  },
  {
    path: '/',
    element: <MainLayout   />,
    children: [
      { path: 'login', element: <LoginView isAuthenticated={isAuthenticated} userHasAuthenticated={userHasAuthenticated} userSession={userSession} setuserSession={setuserSession} props={props}/> },
      { path: 'register', element: <RegisterView userSession={userSession}/> },
      { path: 'verify', element: <VerifyView userSession={userSession}/> },
      { path: '404', element: <NotFoundView userSession={userSession}/> },
      { path: '/', element: <Navigate to="/app/dashboard" /> }, 
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
]};

export default routes;
