import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Container, 
  Grid, 
  makeStyles
} from '@material-ui/core'; 
import ValidationFailed from './ValidationFailed';
import InvoiceValidated from './InvoiceValidated';
import InvoiceRejected from './InvoiceRejected';
import MonthlyUsageChart from './MonthlyUsageChart';
import TrafficBySourceLine from './TrafficBySourceLine';
import TrafficBySource from './TrafficBySource';
import EInvoices from './EInvoices';
const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const EInvoiceDashboard = ({ userSession, matrix,eInvoiceTrafficLine, eInvoiceTraffic, handleSearch, className, ...rest }) => {
  const classes = useStyles(); 
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={3}
            md={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <ValidationFailed matrix={matrix} />
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <InvoiceRejected matrix={matrix} />
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <InvoiceValidated matrix={matrix} />
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <MonthlyUsageChart matrix={matrix} />
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
           <TrafficBySourceLine traffic={eInvoiceTrafficLine} userSession={userSession} sx={{ height: '100%' }} />
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <TrafficBySource traffic={eInvoiceTraffic} userSession={userSession} sx={{ height: '100%' }} />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <EInvoices userSession={userSession} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

EInvoiceDashboard.propTypes = {
  className: PropTypes.string,
  handleSearch: PropTypes.func,
  userSession: PropTypes.object, 
  matrix: PropTypes.object,
  invoiceTrafficLine: PropTypes.object,
  invoiceTraffic: PropTypes.object
};

export default EInvoiceDashboard;
