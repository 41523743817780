import React, { useState,useEffect,useRef } from 'react';
import PropTypes from 'prop-types';
import {CardMedia,} from '@material-ui/core'; 
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import PerfectScrollbar from 'react-perfect-scrollbar'; 
import MenuIcon from '@material-ui/icons/MoreVert';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'; 
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles, 
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Chip,
  MenuItem,
  Menu,
  colors,
  DialogTitle,
  withStyles,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Switch 
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';   
import Amplify, {API } from 'aws-amplify';
import awsconfig from 'src/aws-exports'; 
import Tooltip from '@material-ui/core/Tooltip';
import Spinner from 'src/common/Spinner'; 
import Features from "./Features"; 
import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';
import { Base64 } from 'js-base64'; 
Amplify.configure(awsconfig); 
const useStyles =makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }, 
  greenStatus:{
    backgroundColor:colors.green[600],
    color: "#fff",
  },
  statusPrimary:{
    color: colors.white,
  },
  greyStatus:{
    backgroundColor:"#909090",
    color: "#fff",
  },
  grey:{
    backgroundColor:"#909090",
    color: colors.white,
    fontSize:14,
    width:24,
    height:24,
    margin: "4px",
    display: "inline-flex"
  },
  green:{
    backgroundColor: colors.green[600],
    color: colors.white,
    fontSize:14,
    width:24,
    height:24,
    margin: "4px",
    display: "inline-flex"
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: "100%",
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  cardRoot: {
    maxWidth: 345,
  },
  carAction:{
    flex: "0 0 auto",
    margin:0,
    alignSelf: "center"
  }
 
}));
const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialogClose:{
        position: "absolute",
        right: "8px" ,
        top: "8px", 
        color: colors.grey[600]
      },
      dialogTitle:{
        margin: 0,
        padding: "16px"
      }
  });
const PurpleSwitch = withStyles({
    switchBase: {
      color: colors.purple[300],
      '&$checked': {
        color: colors.purple[500],
      },
      '&$checked + $track': {
        backgroundColor: colors.purple[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);
  function BootstrapTooltip(props) {
    const useStylesBootstrap = makeStyles((theme) => ({
      arrow: {
        color: theme.palette.common.black,
      },
      tooltip: {
        backgroundColor: theme.palette.common.black,
      },
    }));
    const classes = useStylesBootstrap();
    return  <Tooltip arrow classes={classes} {...props} />;
  }
const AccessCode = (props,{ className, ...rest }) => {
 // console.log('uuid',uuid());
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles(); 
  const [row,setRow] = useState({}); 
  const [isLoading, setIsLoading] = useState(false); 
  const [menuAnchor, setMenuAnchor] = useState(null); 
  const [menuOpen, setMenuOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [featureOpen, setFeatureOpen] = useState(false);
  const [accessCode,setAccessCode] = useState('');
  const [filterSwitch,setFilterSwitch]=useState(false);
  const [deviceData, setdeviceData] = useState([{
    pk:"", 
    type:"",
    AccessCode:"",
    Email: "",
    TenantId: "",       
    GSTIN: "",       
    Name: "",
    MobileNo:"",
   Features:[
          {
             Name:"",
             Access:true
          }],
  ConnectedDate:"",
  Imei:"",
  UserID: "",
  Status:""
  }]); 
 const [QRcodebase64,setQRcodebase64]=useState('');
 const [park, setPark] = useState(false); 
 const [verify, setVerify] = useState(false); 
 const [codeType, setCodeType] = useState('Add');

 function randomAplhanumeric(len, arr) { 
  var ans = ''; 
  for (var i = len; i > 0; i--) { 
      ans +=  
        arr[Math.floor(Math.random() * arr.length)]; 
  } 
  return ans; 
}

  let textArea =useRef(null);  
   
  
  const handleModelOpen = (e,rowData) => {  
   checkAccessCode(e,rowData);      
    setDeleteOpen(true);
  };
  
  const handleModelClose = e => {
    setDeleteOpen(false);
  };
  const handleFeatureOpen = e => {
    setFeatureOpen(true);
  };
  async function handleMenuClose() {
    await setMenuOpen(false);
  }
  async function handleFeatureClose() {
    await setFeatureOpen(false);
  }
   
  const handleOpen = () => {
    setOpen(true);
  };
  async function openMenu(event, row) {
    let anchorElement = event.currentTarget;   
    setRow(row);
    if(anchorElement) await setMenuAnchor(anchorElement);
    await setMenuOpen(true); 

  } 
  const [open, setOpen] = useState(false); 
  useEffect(() => {
    const listDevices = async (e) => {
      setIsLoading(true);      
      try {
       let apiName = "OptiVIVeAPI";
       let path = "/module";
       let myInit = {
         headers: { "Content-Type": "application/json" },
         body: {status:["Pending"] ,type: "Device", UserID: props.userSession.UserName, tenantId:props.userSession.TenantId  },
         queryStringParameters: {
           module: "mobile",
           op: "listDevices"
         }
       };
        var response=await API.post(apiName, path, myInit);
        await setdeviceData(response.data);        
        await setIsLoading(false);       
        
     }
     catch (error) {
       console.log(" error",error); 
     } 
    } 
    listDevices()
   },[]);
   const handleUpdate = () => { 
    updatepropertyDB();
    let status='';    
    setTimeout(
      function() {
        StatusChange(status);
        setMenuOpen(false);
      }
      .bind(this),
      1000
  );
  }
 async function updatepropertyDB() { 
    console.log("UPDATE PROPERTY TO DB");
    let apiName = "OptiVIVeAPI";
    let path = "/module";     
    var body = {}; 
    body.type = "Device";
    body.pk = row.pk;
    body.Status ='Delete'; 
    body.tenantId=props.userSession.TenantId 
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: body,   
      queryStringParameters: {
        module: "mobile",
         op: "changeStatus"
      }
    };
    console.log(myInit);

    API.post(apiName, path, myInit).then(response => {
      console.log("UPDATE USER PROPERTY", response);
      setIsLoading(false);
      
      enqueueSnackbar("Status successfully Updated.. !! .",
      { 
        variant: 'success',
      });
      setOpen(false);      
     
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
      enqueueSnackbar(err.message,{ 
        variant: 'error',
      });  
      setOpen(false);     
       
    });
  } 
  const handleFeatures = (verify,park, evt, key, payload) => { 
   let Features=[
    {
       Name:"Park",
       Access:park
    },{
      Name:"Verify",
      Access:verify
   }
  ]; 
    updatestatusDB(Features); 
    setFeatureOpen(false);
    let status='';    
    setTimeout(
      function() {
        StatusChange(status);
        setMenuOpen(false);
      }
      .bind(this),
      1000
  );
   }  
   async function updatestatusDB(Features){
    console.log('Features',Features);
    console.log('row',row);
    let apiName = "OptiVIVeAPI";
    let path = "/module";     
    var body = {}; 
    body.type = "Device";
    body.pk = row.pk;     
    body.Features = Features;   
    body.tenantId=props.userSession.TenantId;
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: body,   
      queryStringParameters: {
        module: "mobile",
        op: "setFeatures"
      }
    };
    console.log(myInit);

    API.post(apiName, path, myInit).then(response => {
      console.log("UPDATE USER PROPERTY", response);
      setIsLoading(false);
      enqueueSnackbar("Permission  saved successfully.. !! .",
      { 
        variant: 'success',
      });
     

      
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
      enqueueSnackbar(err.message,{ 
        variant: 'error',
      }); 
    });

   }
   async function setQRCode(code,p,v){
    try {
     let apiName = "OptiVIVeAPI";
     let path = "/module";
     let myInit = {
       headers: { "Content-Type": "application/json" },
       body:  {  
      "data":Base64.encode(JSON.stringify({"AccessCode":code,
      "Verify":v,
      "Park":p}),true),
      "width":200,
      "height":200 
      , tenantId:props.userSession.TenantId 
      },
       queryStringParameters: {
         module: "mobile",
         op: "barcode"
       }
     };
      var response=await API.post(apiName, path, myInit);
      await setQRcodebase64(response.data); 
      setTimeout(
        function() {
          setIsLoading(false);
        }
        .bind(this),
        3000
    );    
      console.log('QRcodebase64:',QRcodebase64);
   }
   catch (error) {
     console.log(" error",error); 
   } 
  } 
  async function checkAccessCode(type, rowData) {
    console.log('type', type, rowData);
    let code = '';
    if (type === 'Add') {
      setPark(false);
      setVerify(false);
      setCodeType('Add');
      code = randomAplhanumeric(10, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789');
      try {
        let apiName = 'OptiVIVeAPI';
        let path = '/module';
        let myInit = {
          headers: { 'Content-Type': 'application/json' },
          body: {
            AccessCode: code,
            Type: type,
            tenantId:props.userSession.TenantId 
          },
          queryStringParameters: {
            module: 'mobile',
            op: 'checkAccessCode'
          }
        };
        var response = await API.post(apiName, path, myInit);
        if (response.value == 'NotAvailable') {
          setAccessCode(code);
          setQRCode(code, park, verify);
        } else if (response.value == 'Available') {
          await checkAccessCode();
        }
        await setIsLoading(false);
      } catch (error) {
        console.log(' error', error);
      }
    } else {
      setCodeType('View');
      code = rowData.AccessCode;
      rowData.Features.length>0 && rowData.Features.forEach(element => {
          if(element.Name=='Park')
          {
            setPark(element.Access);
          }
          if(element.Name==='Verify')
          {
            setVerify(element.Access);
          }
      });
      setAccessCode(code);
      await setQRCode(code, park, verify);
      await setIsLoading(false);
    }
  }
  const handleChangePark = (event) => {
    setPark(event.target.checked);
    setIsLoading(true);
    setQRCode(accessCode,event.target.checked,verify);
    setIsLoading(false);
      
    
 }; 
 const handleChangeVerify = (event) => {
    setVerify(event.target.checked);
    setIsLoading(true);
    setQRCode(accessCode,park,event.target.checked);
    setIsLoading(false);
      
}; 
async function handleDevicesSave(){
  let Features=[
    {
       Name:"Park",
       Access:park
    },{
      Name:"Verify",
      Access:verify
   }
  ]; 
  let apiName = "OptiVIVeAPI";
  let path = "/module";     
  var body = {}; 
  body.type = "Device";
  body.pk = uuid();    
  body.Features = Features;  
  body.AccessCode=accessCode; 
  body.UserID=props.userSession.UserName;
  body.GSTIN=props.userDetails.GSTIN;
  body.Name=props.userDetails.Name;
  body.TenantId=props.userSession.TenantId;
  body.Email=props.userSession.Email;
  body.Status="Pending";
  let myInit = {
    headers: { "Content-Type": "application/json" },
    body: body,   
    queryStringParameters: {
      module: "mobile",
      op: "deviceUpdate"
    }
  };
  console.log(myInit);
  console.log('  props.userSession  ',props.userDetails);

 
 API.post(apiName, path, myInit).then(response => {
    console.log("UPDATE USER PROPERTY", response);
    setIsLoading(false);
    enqueueSnackbar("Permission  saved successfully.. !! .",
    { 
      variant: 'success',
    });
    let status='';
    StatusChange(status);
    setDeleteOpen(false);
    
  }).catch(err => {
    console.log(err);
    setIsLoading(false);
    enqueueSnackbar(err.message,{ 
      variant: 'error',
    }); 
  }); 

 }
 
  const handleClose = () => {   
    setOpen(false);
  };
 
  const style = {
    float:'right'     
  };
 const copyCodeToClipboard = () => {
    console.log("accessCode",accessCode);
    const ta = document.createElement("textarea");
    ta.innerText = accessCode;
    document.body.appendChild(ta);
    ta.select();
    console.log("ta",ta);
    document.execCommand("copy");
    ta.remove();
  }
 
  const FetaureModel = function(params) {
    return (
      <Dialog
        open={featureOpen}
        onClose={e => handleFeatureClose()}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={"xs"}
      >
        <DialogContent>
        <Features handleFeatures={handleFeatures} content={row.Features}/>
        </DialogContent>
      </Dialog>
    );
  };  
 
  async function StatusChange(status){   
    console.log('New',status);
    setIsLoading(true);      
    try {
     let apiName = "OptiVIVeAPI";
     let path = "/module";
     let myInit = {
       headers: { "Content-Type": "application/json" },
       body: { 
       status:["Pending"] ,
       type: "Device", UserID: 
       props.userSession.UserName, 
       tenantId:props.userSession.TenantId       
        },
       queryStringParameters: {
         module: "mobile",
         op: "listDevices"
       }
     };
      var response=await API.post(apiName, path, myInit);
      await setdeviceData(response.data);        
      await setIsLoading(false);
   }
   catch (error) {
     console.log(" error",error); 
   } 
  } 
   
  const DeviceModel = function(params) {
    return (
      <Dialog
        open={deleteOpen}
        onClose={e => handleModelClose()}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <Card className={classes.cardRoot}>
            <CardMedia className={classes.cover} title="Copy Acces Code">
            <CardMedia component='img' src={`data:image/png;base64, ${QRcodebase64}`} />
            <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              
              <FormControlLabel
              control={(
                <Checkbox checked={verify} onChange={handleChangeVerify}/>
              )}
              label="Verify Invoice"
            />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControlLabel
                control={(
                  <Checkbox checked={park} onChange={handleChangePark}/>
                )}
                label="Park Invoice"
              />
            </Grid>
            </Grid>
              <FormControl
                fullWidth
                className={classes.margin}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-amount">
                  Access Code
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  value={accessCode}  
                  readOnly    
                  ref={(ref) => textArea = ref}        
                  endAdornment={
                    <InputAdornment position="end">
                          <IconButton  onClick={() => copyCodeToClipboard()}>
                          <FileCopyOutlinedIcon/>
                         </IconButton>                  
                    </InputAdornment>
                  }
                  labelWidth={100}
                />
              </FormControl>
            </CardMedia>
            <CardContent>
             
              <Typography gutterBottom variant="h5" component="h2">
                Connect Device
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Please enter the activation code or scan the QR code to connect
                the device to your account
              </Typography>
              <Divider />

       {codeType=='Add'?<Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
             <Button
              variant="contained"
              className="errorIcon"
              color="primary"
               onClick={(event, row) => {  
                handleDevicesSave()
                }}
             >
            Save
          </Button>
        </Box>:<></>}
            </CardContent>
           
          </Card>
        </DialogContent>
      </Dialog>
    );
  };  
   return (

    <div className={classes.root}> 
  <DeviceModel/> 
        <FetaureModel/>  
    <Card>  

    <CardHeader
          classes={{root:classes.carAction}}
          subheader="Device Access Code"
          title="Manage Device Access Code"
          action={

         <>    <Tooltip title="Add"> 
            <Button
  variant="contained"
className="errorIcon"
color="primary"
onClick={(event, row) => {  
 handleModelOpen("Add"); 
}}
 >
Add
</Button></Tooltip>
     </>     }
        />
    <Divider/>  
    <Divider />
      <PerfectScrollbar>
        <Box minWidth={800}>
        <div className={isLoading ? 'parentDisable' : ''} width="100%">
          <div className='overlay-box'>      
        <Spinner left={70} top={0}  isLoading={isLoading} />
        </div>
          </div>  
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                 Access Code
                </TableCell> 
             
                <TableCell>
                Features
                </TableCell>
                <TableCell>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {deviceData && deviceData.map((invoice) => (
                <TableRow
                  hover  
                  key={invoice.pk}
                >
                  <TableCell>
                    {invoice.AccessCode}
                  </TableCell> 
                
                      <TableCell>
                      <Box>
                      {invoice.Features.map((element,index) => {
                          
                         return (
                          <BootstrapTooltip key={index}
                                  title={element.Name}
                              >
                    <Avatar alt="Remy Sharp"  className={element.Access?classes.green:classes.grey}>{element.Name.charAt(0)} </Avatar>
                           </BootstrapTooltip>
)
                          })   }
                         
                         </Box>      
                  </TableCell>
                  <TableCell>
                  <Button        
        onClick={(event, row) => { 
            openMenu(event, invoice);
        }}
          color="primary"
          endIcon={<MenuIcon />}
          size="large"
          variant="text"
        >
         </Button>
                  
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Menu
            id="simple-menu"
            keepMounted
            anchorEl={menuAnchor}
            open={menuOpen}
            onClose={handleMenuClose}
          >  
           <MenuItem onClick={()=>handleModelOpen("View",row)}>
              View Access Code
            </MenuItem>
            <MenuItem onClick={handleFeatureOpen}>
              Set Permission
            </MenuItem>
            <MenuItem onClick={handleOpen}>
              Remove Access Code
            </MenuItem> 
          </Menu>
    </Card>
    <div>       
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
        <DialogContent> 
          <DialogContentText id="alert-dialog-description">
            Do you want to remove the access code?             
          </DialogContentText> 
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            No
          </Button>
          <Button onClick={handleUpdate} color="primary"    
            variant="contained" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>   
  </div>
  );
};

AccessCode.propTypes = {
  className: PropTypes.string
};

export default AccessCode;
