import React from 'react'; 
import { DropzoneAreaBase } from 'material-ui-dropzone';
import { API, Storage } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AttachFile, Description, PictureAsPdf, Theaters } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

const BulkInvoiceUpload = ({ userSession, uploadOpen, handleClick, ...rest }) => {
    const [open, setOpen] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [fileObjects, setFileObjects] = React.useState([]);
    const [invoices, setInvoices] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false); 
    const handleClose = async () => {
        setOpen(false);
        handleClick('open', false);
    };
    const onSave = async () => {
        console.log('onSave', fileObjects);
        let uploaded= await uploadToS3(fileObjects);        
        console.log('uploaded',uploaded);
        setInvoices(uploaded);
        await saveNewInvoices(uploaded);
        setFileObjects([]);
        handleClose();
    };
    React.useEffect(() => {
        setOpen(uploadOpen);
    }, [uploadOpen]);

    function getFileExtension(filename) {
        return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
    }
    const uploadToS3 = async (files) => {
        if (!files) return []
        try {
            let uploaded=[];
            for await (const contents of files.map(file => file)) {
                console.log('uploadToS3', contents);
                let fileName = contents.file.name;
                let fileExt = getFileExtension(fileName);
                let s3Key = (Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)) + '.' + fileExt;
                let result = await Storage.put(s3Key, contents.file, { contentType: contents.file.type });
                console.log('s3put result', result);
                uploaded.push({ FileKey: s3Key, FileName: fileName });                
            }
            return uploaded;
        } catch (error) {
            console.log('upload error', error);
            return error;
        }
    }
    const handlePreviewIcon = (fileObject, classes) => {
        const { type } = fileObject.file
        const iconProps = {
            className: classes.image,
        }
        switch (type) {
            case "application/msword":
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                return <Description {...iconProps} />
            case "application/pdf":
                return <PictureAsPdf {...iconProps} />
            default:
                return <AttachFile {...iconProps} />
        }
    }
    const saveNewInvoices = async (uploaded) => {
        setIsLoading(true);
        try {
         let apiName = "OptiVIVeAPI";
         let path = "/module";
         let myInit = {
           headers: { "Content-Type": "application/json" },
           body: { masterType: "User", userData: userSession, tenantId:userSession.TenantId,invoices:uploaded },
           queryStringParameters: {
             module: "invoice",
             op: "saveNewInvoices"
           }
         };
          var response=await API.post(apiName, path, myInit);  
          if(response.success)
          {
            enqueueSnackbar(response.message,
            { 
              variant: 'success',
            });
          } 
          else
          {
            enqueueSnackbar(response.message,
                { 
                  variant: 'error',
                });
          }      
       }
       catch (error) {
         console.log("set login time error",error); 
         enqueueSnackbar('Failed to upload invoices',
            { 
              variant: 'error',
            });
       } 
     } 
    return (
        <>
            <Dialog
                maxWidth={'sm'}
                disableEscapeKeyDown                
                fullWidth
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Upload Invoice"}</DialogTitle>
                <DialogContent>
                    <DropzoneAreaBase
                        fileObjects={fileObjects}
                        onAdd={newFileObjs => {
                            console.log('onAdd', newFileObjs, fileObjects);
                            setFileObjects([].concat(fileObjects, newFileObjs));
                        }}
                        onDelete={deleteFileObj => {
                            console.log('onDelete', deleteFileObj);
                        }}
                        onClose={handleClose}
                        getPreviewIcon={handlePreviewIcon}
                        showPreviews
                        showPreviewsInDropzone={false}
                        showFileNames={true}
                        showFileNamesInPreview
                        useChipsForPreview
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onSave} color="primary" autoFocus>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default BulkInvoiceUpload;