import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles, CardActions, FormControl, InputLabel, Input, FormHelperText,
  InputAdornment,
  IconButton,
  Paper, OutlinedInput
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import WarningIcon from '@material-ui/icons/Warning';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Amplify, { API } from 'aws-amplify';
import awsconfig from 'src/aws-exports';
import { useSnackbar } from 'notistack';



Amplify.configure(awsconfig);
const useStyles = makeStyles(({
  root: { 
    width: 800 ,
  },
  child_root: { width: 780, 'padding-top': '5px' },
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontSize: 18,
  },
  pos: {
    marginBottom: 12,
  },
  sc: {
    fontSize: 16,
    'margin-top': '10px',
    textAlign: "start"
  },
  paper: {

    textAlign: 'center',
    fontSize: 14,
    color: 'gray',
    marginBottom: 20,
    display: 'flex'
  }
}));





const Trainer = (props, { className, ...rest }) => {
  
  const classes = useStyles();

  const [uniqueField, setUniqueField] = useState('');
  const [type, setType] = useState('');
  const [subtype, setSubtype] = useState('');

  const [addPDFParams, setAddPDFParams] = useState(true);

  const [rows, setRows] = useState([]);

  const [PDFParams,setPDFParams] = useState([]);




  useEffect(() => {
    
    setPDFParams([]);
    setPDFParams(PDFParams => [...PDFParams,props.userDetails.PDFParams]);

    setRows([]);
    setRows(props.userDetails.PDFParams);

    console.log('PDFParams :'+JSON.stringify(props.userDetails.PDFParams))
    console.log('Rows :'+JSON.stringify(rows))

    // if(props.childParameters==1){
    //   handleSaveClick();
    // }
  }, [props]); 



  const handleClick = (e)=>{
    if(uniqueField != '' && type !='' && subtype !=''){
      console.log("Unique Field ::",uniqueField);
      console.log("Type ::",type);
      console.log("Subtype ::",subtype);

      console.log("Before PDFParams ::",PDFParams);
      
      if(PDFParams == ''){
        setPDFParams([]);
        setPDFParams(PDFParams => [...PDFParams,{
          uniqueField : uniqueField,
          type: type,
          subtype: subtype,
        }]);
      }
      else{
        setPDFParams(...PDFParams);
        console.log("Second", PDFParams);
        setPDFParams(PDFParams => [...PDFParams,{
          type: type,
          uniqueField : uniqueField,
          subtype: subtype,
        }
      ]);
    }

      console.log("After PDFParams ::",PDFParams);
    }
  }

  const evaluate = () =>{

    if(uniqueField != '' && type !='' && subtype !=''){
      setAddPDFParams(false);
    } else{
      setAddPDFParams(true);
    }
    console.log("Rows",rows);
  }

 
  return (
  <>
    <Box mb={2}>
      <form
      onSubmit={props.handlePDFParams.bind(null,PDFParams)}
        className={clsx(classes.root, className)}
        {...rest}
      >

        <Card className={classes.root}>

          <Box m={2}>
            <Grid container spacing={2}>

              <Grid item xs={3}>
                <TextField
                  id="uniqueField"
                  label="Unique Field"
                  variant="outlined"
                  color="secondary"
                  onKeyUp={()=>evaluate()}
                  onChange={(e)=>{setUniqueField(e.target.value); evaluate()}}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  id="type"
                  label="Type"
                  variant="outlined"
                  color="secondary"
                  onKeyUp={()=>evaluate()}
                  onChange={(e)=>{setType(e.target.value);evaluate()}}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  id="subtype"
                  label="Subtype"
                  variant="outlined"
                  color="secondary"
                  onKeyUp={()=>evaluate()}
                  onChange={(e)=>{setSubtype(e.target.value);evaluate()}}
                />
              </Grid>

              <Grid item xs={3} >
                <Button 
                id="addPDFParams"
                type='submit'
                variant="contained" 
                color="primary" style={{height:"100%"}}
                disabled = {addPDFParams}
                onClick = {handleClick}
                >
                  Add
                </Button>
              </Grid>

            </Grid>

          </Box>

        </Card>
      </form>
    </Box>

    <PDFList rows = {rows} />
    
  </>
  );
};

// function createData(uniqueField, type, subtype, position) {
//   return { uniqueField, type, subtype, position };
// }



// const rows = [
//   // createData('AFKPU1616A',"Invoice","Material"),
//   // createData('BGLQV2727B',"Invoice","Material"),
//   // createData('CHMRW3838C',"Invoice","Material"),
//   // createData('DINSX4949D',"Invoice","Material"),
//   // createData('EJOTY5150E',"Invoice","Material"),
// ];


const PDFList = (props, { className, ...rest }) =>{

  const classes = useStyles();

  const rows = props.rows

  // useEffect(()=>{

  // console.log("rows", rows);


  // })


 

  // console.log("rows", rows);


  return (
    <form>
      <Card className={classes.root}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Unique Field</TableCell>
                <TableCell align="right">Type</TableCell>
                <TableCell align="right">Subtype</TableCell>
                {/* <TableCell align="right">Action</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows == undefined ? '' : rows.map((row) => (
                <TableRow key={row.uniqueField}>
                  <TableCell component="th" scope="row">
                    {row.uniqueField}
                  </TableCell>
                  <TableCell align="right">{row.type}</TableCell>
                  <TableCell align="right">{row.subtype}</TableCell>
                  {/* <TableCell align='right'>
                    <Button variant="outlined" startIcon={<DeleteIcon />}>
                      Delete
                    </Button>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>   
    </form>

  );

}




// Inbound.propTypes = {
//   className: PropTypes.string,
//   handleAdd:PropTypes.func,
//   handleDelete:PropTypes.func,
//   handleRotate:PropTypes.func
// };

export default Trainer;
