import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchBox:{
    minWidth: '45%',
    float: 'left',
    display: 'flex',
    justifyContent: 'flex-start',    
    padding: '16px'
  },
  buttonBox:{
    minWidth: '45%',
    float: 'right',
    display: 'flex',
    justifyContent: 'flex-end', 
    padding: '24px 16px' 
  },
  toolbarContent:{
    padding:0
  }
}));

const Toolbar = ({ className, handleSearch, ...rest }) => {
 
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
 
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box className={classes.searchBox}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search vendor"
                variant="outlined"
                id="searchItem"                 
                onKeyPress={handleSearch}
                onChange={handleSearch}
              />
            </Box>
            <Box className={classes.buttonBox}>
         
        <Button className={classes.exportButton}>
          Export
        </Button>
         
      </Box>  
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  handleSearch:PropTypes.func
};

export default Toolbar;
