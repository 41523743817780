import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Container, 
  Grid, 
  makeStyles
} from '@material-ui/core'; 
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { green } from '@material-ui/core/colors';
import InvoiceProcessed from './InvoiceProcessed';
import ValidationFailed from './ValidationFailed'; 
import InvoiceRejected from './InvoiceRejected';
import MonthlyUsageChart from './MonthlyUsageChart';
import TrafficBySourceLine from './TrafficBySourceLine';
import TrafficBySource from './TrafficBySource';
import Invoices from './Invoices';
import BulkInvoiceUpload from './BulkInvoiceUpload'
const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor:  green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
}));

const InvoiceDashboard = ({ userSession, matrix,invoiceTrafficLine, invoiceTraffic, handleSearch, className, ...rest }) => {
  const classes = useStyles();
  const [uploadOpen, setUploadOpen] = React.useState(false);
  const handleFileUpload= async (type, e)=>{
    console.log('handleFileUpload',type, e);
      if(type==='open') setUploadOpen(e);
  }  
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <BulkInvoiceUpload userSession={userSession} handleClick={handleFileUpload} uploadOpen={uploadOpen}/>
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        > 
          <Grid
            item
            lg={3}
            md={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <ValidationFailed matrix={matrix} />
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <InvoiceRejected matrix={matrix} />
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <InvoiceProcessed matrix={matrix} />
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <MonthlyUsageChart matrix={matrix} />
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
           <TrafficBySourceLine traffic={invoiceTrafficLine} userSession={userSession} sx={{ height: '100%' }} />
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <TrafficBySource traffic={invoiceTraffic} userSession={userSession} sx={{ height: '100%' }} />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Invoices userSession={userSession} />
          </Grid>
        </Grid> 
        <Fab aria-label={'Add'} onClick={()=> {
          setUploadOpen(true);
        }} className={classes.fab} color={'primary'}>
        <AddIcon />
          </Fab>
      </Container>
    </div>
  );
};

InvoiceDashboard.propTypes = {
  className: PropTypes.string,
  handleSearch: PropTypes.func,
  userSession: PropTypes.object, 
  matrix: PropTypes.object,
  invoiceTrafficLine: PropTypes.object,
  invoiceTraffic: PropTypes.object
};

export default InvoiceDashboard;
