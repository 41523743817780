import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Grid
} from '@material-ui/core';
import Page from 'src/components/Page';
import Amplify, { API } from 'aws-amplify';
import awsconfig from 'src/aws-exports';
import PropTypes from 'prop-types';
import { Tabs, Tab, AppBar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import Spinner from 'src/common/Spinner';
import BusinessPlaces from './BusinessPlaces';
import Devices from './Devices';
import AccessCode from './AccessCode';
import Inbound from './Inbound';
import Outbound from './Outbound';
import Trainer from './Trainer';
import { v4 as uuid } from 'uuid';
import usePersistedStateString from 'src/utils/usePersistedStateString'
Amplify.configure(awsconfig);
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  fabSpinner: {
    display: 'inline-block',
    position: 'relative',
    marginLeft: '50%'

  }
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `settings-tab-${index}`,
    'aria-controls': `settings-tabpanel-${index}`,
  };
}
const SettingsView = (props) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = usePersistedStateString('value', 0, 'number');
  const { enqueueSnackbar } = useSnackbar();
  const [pdfparamsDetails, setpdfparamsDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [childParameters, setchildParameters] = useState("");
  const [tenantKeys, setTenantKeys] = useState([]);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  useEffect(() => {
    const waitfun = async () => {
      await setIsLoading(true);
      await getUserAPIKey();
      await getUser();
      await setIsLoading(false);
      console.log("SettingsView tenantKeys", tenantKeys);
    }
    waitfun();
  }, []);

  const getUser = async () => {
    try {
      let apiName = "OptiVIVeAPI";
      let path = "/module";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: { masterType: "User", UserID: props.userSession.UserName, TenantId: props.userSession.TenantId },
        queryStringParameters: {
          module: "user",
          op: "getUserProfile"
        }
      };
      var response = await API.post(apiName, path, myInit);
      await setUserDetails(response.data);
    }
    catch (error) {
      console.log("set login time error", error);
    }
  }

  const getUserAPIKey = async () => {
    try {
      let apiName = "OptiVIVeAPI";
      let path = "/module";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: { masterType: "User", UserID: props.userSession.UserName, TenantId: props.userSession.TenantId },
        queryStringParameters: {
          module: "user",
          op: "getUserAPIKey"
        }
      };
      var response = await API.post(apiName, path, myInit);
      await setTenantKeys(response.data);
    }
    catch (error) {
      console.log("set login time error", error);
    }
  }


  const handleGSTIN = (b, evt, key, payload) => {
    console.log('Bussiness', b);
    updateGstininDB(b);
  }

  async function updateGstininDB(b) {
    console.log("UPDATE PROPERTY TO DB");
    let apiName = "OptiVIVeAPI";
    let path = "/module";
    var body = {};
    body.masterType = "User";
    body.userData = props.userSession;
    body.BusinessPlaces = b;
    body.tenantId = props.userSession.TenantId
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: body,
      queryStringParameters: {
        module: "user",
        op: "setbusinessPlaces"
      }
    };
    console.log(myInit);

    API.post(apiName, path, myInit).then(response => {
      console.log("UPDATE USER PROPERTY", response);
      //setIsLoading(false);
      getUser();
      setchildParameters(1);
      if (response.success) {
        enqueueSnackbar("Business place registered successfully.. !! .", {
          variant: 'success',
        });
      }
      else {
        enqueueSnackbar("Something went wrong. Try after sometime!!", {
          variant: 'error',
        });
      }
    }).catch(err => {
      console.log(err);
      //setIsLoading(false); 
      setchildParameters(1);

    });
  }

  const generateKey = async (e, name) => {
    try {
      let apiName = "OptiVIVeAPI";
      let path = "/module";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: {},
        queryStringParameters: {
          module: "user",
          op: "getKey"
        }
      };
      var response = await API.post(apiName, path, myInit);
    }
    catch (error) {
      console.log("set login time error", error);
    }
  }

  async function keyGenerate(e, name) {
    var body = {};
    body.APIType = e.APIType;
    body.CompanyName = e.CompanyName;
    body.Name = name;
    body.TenantId = e.TenantId;
    body.type = e.type;
    body.UserEmail = e.UserEmail;
    body.UserID = e.UserID;
    body.UserName = e.UserName;
    body.pk = "";
    let apiName = "OptiVIVeAPI";
    let path = "/module";
    console.log(body);
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: body,
      queryStringParameters: {
        module: "user",
        op: "generateInOutboundAPI"
      }
    };
    console.log(myInit);

    API.post(apiName, path, myInit).then(response => {
      console.log("UPDATE USER PROPERTY", response);
      setTimeout(
        function () {
          getUserAPIKey();
        }
          .bind(this),
        500
      );
      var f=e;
      f.Name=name;
      activityLog(f, 'Add');
      enqueueSnackbar("API successfully Added.. !! .",
        {
          variant: 'success',
        });


    }).catch(err => {
      console.log(err);
      enqueueSnackbar(err.message, {
        variant: 'error',
      });
    });
  }
  const handleAdd = async (f, evt, key, payload) => {
    await keyGenerate(f, evt);
  }
  const handleDelete = async (f, evt, key, payload) => {
    console.log(f);
    console.log('evt.pk', f.pk);
    let apiName = "OptiVIVeAPI";
    let path = "/module";
    console.log(f);
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: f,
      queryStringParameters: {
        module: "user",
        op: "deleteInOutboundAPI"
      }
    };
    console.log(myInit);

    API.post(apiName, path, myInit).then(response => {
      console.log("UPDATE USER PROPERTY", response);
      setTimeout(
        function () {
          getUserAPIKey();
        }
          .bind(this),
        500
      );
      activityLog(f, 'Delete');
      enqueueSnackbar("API successfully Deleted.. !! .",
        {
          variant: 'success',
        });


    }).catch(err => {
      console.log(err);
      enqueueSnackbar(err.message, {
        variant: 'error',
      });
    });
  }

  const handleRotate = async (f, evt, key, payload) => {
    console.log(f);
    console.log('evt.pk', f.pk);
    let apiName = "OptiVIVeAPI";
    let path = "/module";
    console.log(f);
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: f,
      queryStringParameters: {
        module: "user",
        op: "rotateInOutboundAPI"
      }
    };
    console.log(myInit);

    API.post(apiName, path, myInit).then(response => {
      console.log("UPDATE  PROPERTY", response);
      setTimeout(
        function () {
          getUserAPIKey();
        }
          .bind(this),
        500
      );
      activityLog(f, 'Rotate');
      enqueueSnackbar(response.message + ".. !! .",
        {
          variant: 'success',
        });


    }).catch(err => {
      console.log(err);
      enqueueSnackbar(err.message, {
        variant: 'error',
      });
    });
  }
  async function activityLog(e, type) {
    var body = {};
    body.pk = uuid();
    body.Name = e.Name;
    body.TenantId = e.TenantId;
    body.UserID = e.UserID;
    body.type = type;
    body.Message = e.APIType + " key " + type;
    console.log('Activity Log', body);
    let apiName = "OptiVIVeAPI";
    let path = "/module";
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: body,
      queryStringParameters: {
        module: "user",
        op: "APILog"
      }
    };
    console.log(myInit);

    API.post(apiName, path, myInit).then(response => {
      console.log("UPDATE USER PROPERTY", response);


    }).catch(err => {
      console.log(err);

    });
  }

  // const pdfParms = async()=>{
  //   try {
  //     fetch("http://localhost:3002/pdfparams")
  //     .then(res => res.json())
  //     .then((result)=> {console.log("result",result); setpdfparamsDetails(result.data)})
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }



  const handlePDFParams = (b, evt, key, payload) => {
    console.log('PDF Params ::', b);
    evt.preventDefault();

    let isDuplicate = false;
    for(var i=0; i<b.length; i++){
      var c = b[i]
      for(var j=0;j<b.length; j++){
        if(j==i){
          continue;
        }
        else{
          if(c.uniqueField == b[j].uniqueField && c.type == b[j].type && c.subtype==b[j].subtype){
            isDuplicate = true;
            break;
          }
        }
      }
    }
    
    console.log("Duplicate Found : ", isDuplicate);

    if(isDuplicate){
      enqueueSnackbar("Duplicate entries found !! .", {
        variant: 'error',
      });
      getUser();
      setchildParameters(1);
    }else{
      updatePDFParamsDB(b);
    }


  }

  async function updatePDFParamsDB(b) {
    console.log("UPDATE PDF PARAMS TO DB");
    let apiName = "OptiVIVeAPI";
    let path = "/module";
    var body = {};
    body.masterType = "User";
    body.userData = props.userSession;
    body.PDFParams = b;
    body.tenantId = props.userSession.TenantId
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: body,
      queryStringParameters: {
        module: "user",
        op: "setPDFParams"
      }
    };
    console.log(myInit);

    API.post(apiName, path, myInit).then(response => {
      console.log("UPDATE USER PROPERTY", response);
      //setIsLoading(false);
      getUser();
      setchildParameters(1);
      if (response.success) {
        enqueueSnackbar("PDF parameters are added successfully.. !! .", {
          variant: 'success',
        });
      }
      else {
        enqueueSnackbar("Something went wrong. Try after sometime!!", {
          variant: 'error',
        });
      }
    }).catch(err => {
      console.log(err);
      //setIsLoading(false); 
      setchildParameters(1);

    });
  }




  return (
    <Page
      className={classes.root}
      title="Settings"
    ><div>
        <AppBar position="static" style={{ background: 'white' }}>
          <Tabs value={tabValue} onChange={handleChange} aria-label="Profile tabs">
            <Tab style={{ color: '#2E3B55' }} label="Business Places" {...a11yProps(0)} />
            <Tab style={{ color: '#2E3B55' }} label="Devices" {...a11yProps(1)} />
            <Tab style={{ color: '#2E3B55' }} label="Access Code" {...a11yProps(2)} />
            <Tab style={{ color: '#2E3B55' }} label="Outbound API" {...a11yProps(3)} />
            <Tab style={{ color: '#2E3B55' }} label="Inbound API" {...a11yProps(4)} />
            <Tab style={{ color: '#2E3B55' }} label="SFTP" {...a11yProps(5)} />
            <Tab style={{ color: '#2E3B55' }} label="Trainer" {...a11yProps(6)} />
          </Tabs>
        </AppBar>
        <Spinner left={70} top={0} className={classes.fabSpinner} isLoading={isLoading} />
        <TabPanel value={tabValue} index={0}>
          <Container maxWidth="lg">
            <Grid
              container
              spacing={3}
            >

              <Grid
                item
                lg={8}
                md={8}
                xs={12}
              >
                <BusinessPlaces handleGSTIN={handleGSTIN} childParameters={childParameters} userDetails={userDetails} userSession={props.userSession} />
              </Grid>

            </Grid>
          </Container>    </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Devices userDetails={userDetails} userSession={props.userSession} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <AccessCode userDetails={userDetails} userSession={props.userSession} />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <Outbound handleRotate={handleRotate} handleDelete={handleDelete} handleAdd={handleAdd} tenantKeys={tenantKeys} userDetails={userDetails} userSession={props.userSession} />

        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <Inbound handleRotate={handleRotate} handleDelete={handleDelete} handleAdd={handleAdd} tenantKeys={tenantKeys} userDetails={userDetails} userSession={props.userSession} />
        </TabPanel>
        <TabPanel value={tabValue} index={5}>
          {"SFTP"}
        </TabPanel>
        <TabPanel value={tabValue} index={6}>
          <Trainer data={pdfparamsDetails} handlePDFParams={handlePDFParams} handleDelete={handleDelete} tenantKeys={tenantKeys} userDetails={userDetails} userSession={props.userSession} />
        </TabPanel>
      </div>

    </Page>
  );
};

export default SettingsView;
