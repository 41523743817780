import React,{useState} from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core'; 
import md5 from 'crypto-js';
import Page from 'src/components/Page';
import Amplify, { Auth,API } from 'aws-amplify';
import awsconfig from 'src/aws-exports';
import { useSnackbar } from 'notistack';
import Spinner from 'src/common/Spinner';
Amplify.configure(awsconfig);
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const setLoginTime = async (userSession) => {
    try {
      let apiName = "OptiVIVeAPI";
      let path = "/module";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: { masterType: "User", userData: userSession, tenantId:props.userSession.TenantId  },
        queryStringParameters: {
          module: "user",
          op: "setLoginTime"
        }
      };
      await API.post(apiName, path, myInit);
    }
    catch (error) {
      console.log("set login time error",error); 
    }
  }   
  async function handleSubmit(event) { 
    setIsLoading(true);
    try {
      console.log("MD5 PASSWORD ", md5.MD5(event.password).toString());
      console.log("ORGINAL :", event.password +":");
     
      //const user =
      await Auth.signIn(event.email, event.password/* md5.MD5(event.password).toString() */).then(async (user)=>{        
        console.log("user signin", user);
        Object.assign(props.userSession,{
          FirstName: user.signInUserSession.idToken.payload["custom:firstname"],
          LastName: user.signInUserSession.idToken.payload["custom:lastname"],
          Email: user.signInUserSession.idToken.payload["email"],
          Name: user.signInUserSession.idToken.payload["name"],
          UserName: user.signInUserSession.idToken.payload["cognito:username"],
          PhoneNumber: user.signInUserSession.idToken.payload["phone_number"],
          TenantId: user.signInUserSession.idToken.payload["custom:tenantid"],
          AccountType: user.signInUserSession.idToken.payload["custom:accounttype"]
        });  
        setLoginTime(props.userSession);     
        localStorage.email = user.signInUserSession.idToken.payload["email"];  
        props.userHasAuthenticated(true);      
        navigate('/app/dashboard', { replace: true })
      });   
    } catch (err) {
      console.log("Login Error", err);
      setIsLoading(false); 
      enqueueSnackbar(err.message,{ 
        variant: "error",
      });
      if (err.code === "UserNotConfirmedException") {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
      } else if (err.code === "PasswordResetRequiredException") {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
      } else if (err.code === "NotAuthorizedException") {
        // The error happens when the incorrect password is provided
      } else if (err.code === "UserNotFoundException") {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
      } else {
        console.log(err);
      }
    }
  }
   
  return (
    <Page
      className={classes.root}
       title=""
    >
      <Spinner left={70} top={0}  isLoading={isLoading} />
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={async (e) => {
             /*  navigate('/app/dashboard', { replace: true }); */
             await handleSubmit(e);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Sign in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Sign in on the internal platform
                  </Typography>
                </Box>  
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Don&apos;t have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/register"
                    variant="h6"
                  >
                    Sign up
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
