import React, { useState } from 'react';
import clsx from 'clsx'; 
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types'; 
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu'; 
import MenuIcon from '@material-ui/icons/MoreVert';
import ReactJson from 'react-json-view'
import Moment from 'react-moment'; 
import Toolbar from './Toolbar';
import {
  Box, 
  Card, 
  makeStyles,
  Dialog,
  DialogContent, 
  Typography,
  IconButton
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle'; 
import MaterialTable from "material-table";
import usePersistedState from 'src/utils/usePersistedState';
import { API } from "aws-amplify"; 
import CloseIcon from '@material-ui/icons/Close';  
 
const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  tooltipText: {
    wordWrap:"nowrap",
    float:"left",
    paddingRight: "9px",
    verticalAlign: "middle",
    display: "inline-flex",
    fontWeight: 500,
    color: "rgba(0, 0, 0, 0.78)",
    fontSize: "0.875rem"
    },
    toolbarCss:{
      'margin-bottom':'15px'
    }
}));

const ListInvoices = (props,{ className, ...rest }) => {
  console.log("props",props);
  const classes = useStyles();  
  const [isLoading, setIsLoading] = useState(false); 
  const [pageSize, setPageSize] = usePersistedState('pageSize', 5); 
  const [currentRow, setCurrentRow] = useState({}); 
  const [menuAnchor, setMenuAnchor] = useState(null); 
  const [menuOpen, setMenuOpen] = useState(false); 
  const [invoicefilter, setInvoicefilter] = useState("");
  const [invoicejson, setInvoicejson] = useState({});
  const [invoiceOpen, setInvoiceOpen] = useState(false);
  const tableRef = React.createRef();
  const [queryData, setqueryData] = useState(""); 
  const handleClickOpen = (e) => {   
      setInvoiceOpen(true); 
  };
  const handleClose1 = (e) => {
    setInvoiceOpen(false);
  };
  const DialogTitle =  (props => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className="addnew-user-root" {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className="addnew-user-close-button" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  }); 
  async function GetInvoiceJson() { 
    let body = {};
    var   masterType = "",
      apiModule = "",
      op = "";
    setIsLoading(true);
    setPageSize(pageSize); 
    masterType = "Pdf Invoice";
    apiModule = "invoice";
    op = "getInvoiceJson";
    body = {
      type: masterType,  
      pk:currentRow.pk,   
      UserID: props.userSession.UserName, 
      tenantId:props.userSession.TenantId      
    };
    body.userData = props.userSession;
    return new Promise((resolve, reject) => {
      let apiName = "OptiVIVeAPI";
      let path = "/module";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: body,
        queryStringParameters: {
          module: apiModule,
          op: op
        }
      };
      API.post(apiName, path, myInit).then(response => {
        setIsLoading(false);
        resolve(response);
      }).catch(err => {
        console.log(err); reject(err);
      });
    });
  } 
  async function openMenu(event, row) {
    let anchorElement = event.currentTarget;
    await setCurrentRow(row);
    if(anchorElement) await setMenuAnchor(anchorElement);
    await setMenuOpen(true);
  }
  async function  viewJson() { 
    var invJson=await GetInvoiceJson();
    await setInvoicejson(invJson);
    handleClickOpen();
 }
  async function GetPagingData(query) {
    setqueryData(query);
    var pageSize = query.pageSize;
    var pageNo = (query.page + 1);
    let body = {}; 
    setIsLoading(true);
    setPageSize(pageSize);  
    body = {
      type: "Pdf Invoice",      
      UserID: props.userSession.UserName, 
      tenantId:props.userSession.TenantId,
      Filter: invoicefilter,
      pageSize: pageSize,
      pageNo: pageNo,
      status:["Processed","Rejected"]
    };
    body.userData = props.userSession;
    return new Promise((resolve, reject) => {
      let apiName = "OptiVIVeAPI";
      let path = "/module";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: body,
        queryStringParameters: {
          module: "invoice",
          op: "listRecentInvoices"
        }
      };
      API.post(apiName, path, myInit).then(response => {
        setIsLoading(false);
        resolve(response);
      }).catch(err => {
        console.log(err); reject(err);
      });
    });
  } 
  async function openMenu(event, row) {
    let anchorElement = event.currentTarget;
    await setCurrentRow(row);
    if(anchorElement) await setMenuAnchor(anchorElement);
    await setMenuOpen(true); 

  }
 
 async function handleMenuClose(){
  await setMenuOpen(false);
 }
 async function downloadInvoice(type) { 
     let body = {}; 
    let apiName = "OptiVIVeAPI";
    let path = "/module"; 
    body = {
      type: type,  
      pk:currentRow.pk,   
      UserID: props.userSession.UserName, 
      tenantId:props.userSession.TenantId      
    };
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body:body,
      queryStringParameters: {
        module:"invoice",
        op: "downloadInvoice"
      }
    };
    await API.post(apiName, path, myInit).then(response => { 
      if(response.success){
      const link = document.createElement('a');
      link.href = response.link;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      }
      
    }).catch(err => {
      console.log(err);
    }); 
  } 
  async function handleSearch(e) {
    let target = e.target;
    if (e != undefined) {
      console.log(target.value);
      setInvoicefilter(target.value);
      tableRef.current.onQueryChange({ page: 0, Filter: target.value })
    }
  }
  return (
  <>
    <Dialog open={invoiceOpen} onClose={e => handleClose1()} fullWidth={true}
        maxWidth={"md"} aria-labelledby="form-dialog-title">
        <DialogTitle   id="email-dialog-title" onClose={e => handleClose1()}>
          JSON View
        </DialogTitle>
        <DialogContent>
        <ReactJson src={invoicejson} enableClipboard={true} />
        </DialogContent>
      </Dialog>
      <Toolbar className={classes.toolbarCss} handleSearch={handleSearch}/>
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>

          <MaterialTable
            tableRef={tableRef}
            columns={[
              {
                title: 'Invoice No',
                field: 'InvoiceNo',
                searchable: false,
                width:"5%", 
              },
              {
                title: 'Vendor',
                field: 'SellerName',
                searchable: false ,
                width:"15%"
              },
              {
                title: 'Invoice Date',
                field: 'InvoiceDate',
                searchable: false,
                render: rowData => <Moment format="DD/MM/YYYY">{rowData.InvoiceDate}</Moment>,
                width:"5%"
              },
              {
                title: 'Received On',
                field: 'ProcessedDate',
                searchable: false,
                render: rowData => <Moment format="DD/MM/YYYY">{rowData.ProcessedDate}</Moment>,
                width:"5%"
              },
              {
                title: 'Source',
                field: 'source',
                searchable: false,
                width:"5%"
              } 
            ]}
           
            data={async query =>
              new Promise((resolve, reject) => {
                GetPagingData(query).then(result => {
                  resolve({
                    data: result.data,
                    page: result.page,
                    totalCount: result.total
                  });
                });
              })
            }
            options={{
              searchFieldStyle: {
                display: 'none'
              },
              actionsColumnIndex: -1,
              search: false,
              showTitle: false,
              toolbar: false,
              pageSize: pageSize,
              pageSizeOptions: [5, 25, 50, 100, 200]
            }}
            actions={[
              {
                icon: ()=> <MenuIcon/>,
                tooltip: 'Actions',
                isFreeAction: false,
                onClick: (event, row) => {
                  openMenu(event, row);
                }
              }
           ]}
          />
         
        </Box>
      </PerfectScrollbar>
      <Menu
            id="simple-menu"
            keepMounted
            anchorEl={menuAnchor}
            open={menuOpen}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={viewJson}>View Invoice Json</MenuItem>
            <MenuItem onClick={()=>downloadInvoice("json")}>
              Download Invoice Json 
            </MenuItem>
            <MenuItem onClick={()=> downloadInvoice("pdf")}>
              Download Invoice PDF 
            </MenuItem>
          </Menu>
    </Card>
  </>);
};

ListInvoices.propTypes = {
  className: PropTypes.string
};

export default ListInvoices;
