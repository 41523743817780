import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles, CardActions, FormControl, InputLabel, Input, FormHelperText,
  InputAdornment,
  IconButton,
  Paper, OutlinedInput
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import WarningIcon from '@material-ui/icons/Warning';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Amplify, { API } from 'aws-amplify';
import awsconfig from 'src/aws-exports';
import { useSnackbar } from 'notistack';
Amplify.configure(awsconfig);
const useStyles = makeStyles(({
  root: { width: 800 },
  child_root: { width: 780, 'padding-top': '5px' },
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontSize: 18,
  },
  pos: {
    marginBottom: 12,
  },
  sc: {
    fontSize: 16,
    'margin-top': '10px',
    textAlign: "start"
  },
  paper: {

    textAlign: 'center',
    fontSize: 14,
    color: 'gray',
    marginBottom: 20,
    display: 'flex'

  }
}));

const Outbount = (props, { className, ...rest }) => {
  const classes = useStyles();
  const [tenantKeys, setTenantKeys] = useState([]);
  const [selected, setSelected] = useState("");
  const [OutboundProd2, setOutboundProd2] = useState(true);
  const [OutboundSand2, setOutboundSand2] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  console.log("props.tenantKeys", props.tenantKeys);
  useEffect(() => {
    setTenantKeys(props.tenantKeys);
  }, [props]);
  const [values, setValues] = React.useState({
    OutboundProduction2: false,
    OutboundProduction1: false,
    OutboundSandbox1: false,
    OutboundSandbox2: false,
  });
  let myInput = null;
  const handleClickCopyKey = (e, name) => {
    console.log(e, values);
    setSelected(name);
    if (e === "OutboundProduction2") {
      setValues({ ...values, OutboundProduction2: !values.OutboundProduction2 });
    }
    if (e === "OutboundProduction1") {
      setValues({ ...values, OutboundProduction1: !values.OutboundProduction1 });
    }
    if (e === "OutboundSandbox1") {
      setValues({ ...values, OutboundSandbox1: !values.OutboundSandbox1 });
    }
    if (e === "OutboundSandbox2") {
      setValues({ ...values, OutboundSandbox2: !values.OutboundSandbox2 });
    }
    myInput.select();
    document.execCommand("copy");
    alert("Copied the text: " + myInput.value);

  }
  const handleClickShowPassword = (e) => {
    console.log(e, values);
    if (e === "OutboundProduction2") {
      setValues({ ...values, OutboundProduction2: !values.OutboundProduction2 });
    }
    if (e === "OutboundProduction1") {
      setValues({ ...values, OutboundProduction1: !values.OutboundProduction1 });
    }
    if (e === "OutboundSandbox1") {
      setValues({ ...values, OutboundSandbox1: !values.OutboundSandbox1 });
    }
    if (e === "OutboundSandbox2") {
      setValues({ ...values, OutboundSandbox2: !values.OutboundSandbox2 });
    }

  };
  

  console.log('tenantKeys', tenantKeys);
  var keyProd = tenantKeys.some(item => "OutboundProduction2" === item.Name);
  if (keyProd != OutboundProd2)
    setOutboundProd2(keyProd);
  var keySand = tenantKeys.some(item => "OutboundSandbox2" === item.Name);
  if (keySand != OutboundSand2)
    setOutboundSand2(keySand);


  if (tenantKeys.some(item => "OutboundSandbox2" === item.Name)) {
    //loginButton = <LogoutButton />;
  }
   
  

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <form
      className={clsx(classes.root, className)}
      {...rest}
    >
      <input hidden value={selected} ref={(ref) => myInput = ref} />
      <Card className={classes.root}>
        <CardContent>

          <CardActions>
            <Grid
              container
              spacing={3}
            >
              <Grid item xs={8}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                Outbound API Keys
        </Typography>
              </Grid>
              <Grid item xs={4}>
                <Button size="small">Learn More in the docs<NavigateNextIcon /></Button>
              </Grid>
            </Grid>
          </CardActions>
          <Divider />
          <Grid
            container
            spacing={3}
          >
            {/*        <Grid item xs={12}>
      <Typography className={classes.sc} color="textSecondary" gutterBottom>
        Secrets
      </Typography>
      </Grid> */}
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"

                >
                  <Grid item xs={1}>
                    <WarningIcon style={{ fontSize: 20 }} />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography className={classes.sc} color="textSecondary" gutterBottom>
                      Never Share your secret keys in plaintext with anyone,Including paid employees.If a secret key is compromised,please rotate it.
           </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {tenantKeys && tenantKeys.length > 0 && tenantKeys.map((Keys, index) => {
              console.log("tenantKeys #####", values["showProd" + (index + 1)]);
              return Keys.APIType === "Production" && Keys.type === "Outbound API Key" ?
                (
                  <Grid key={index} item xs={12}>
                    <FormControl fullWidth className={classes.child_root} variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-amount">Production API Key</InputLabel>
                      <OutlinedInput
                        id="ProductionKey"
                        type={values[Keys.Name] ? 'text' : 'password'}
                        value={Keys.pk}
                        endAdornment={
                          <InputAdornment position="end">
                            {OutboundProd2 ? "" : <Tooltip title="Add">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={props.handleAdd.bind(null,Keys, 'OutboundProduction2')} 
                                //onClick={() => { handleAdd(Keys, 'InboundProduction2') }}
                                onMouseDown={handleMouseDownPassword}
                              >
                                <AddCircleIcon fontSize="small" />

                              </IconButton></Tooltip>}
                            <Tooltip title="View">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => { handleClickShowPassword(Keys.Name) }}
                                onMouseDown={handleMouseDownPassword}
                              >
                                <Visibility fontSize="small" />
                              </IconButton></Tooltip>
                            <Tooltip title="Copy">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => { handleClickCopyKey(Keys.Name, Keys.pk) }}
                                onMouseDown={handleMouseDownPassword}
                              >
                                <FileCopyOutlinedIcon fontSize="small" />
                              </IconButton></Tooltip>
                            <Tooltip title="Rotate">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={props.handleRotate.bind(null,Keys, 'OutboundProduction2')}
                                onMouseDown={handleMouseDownPassword}
                              >
                                <RotateLeftIcon fontSize="small" />
                              </IconButton></Tooltip>
                              {Keys.Name=="OutboundProduction2" ?
                            <Tooltip title="Delete">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={props.handleDelete.bind(null,Keys, 'OutboundProduction2')} 
                                 onMouseDown={handleMouseDownPassword}
                              >
                                <DeleteIcon fontSize="small" />

                              </IconButton></Tooltip> :""}
                          </InputAdornment>
                        }
                        labelWidth={140}
                      />
                    </FormControl>
                  </Grid>
                ) : Keys.APIType === "Sandbox" && Keys.type === "Outbound API Key" ?
                  (<Grid key={index} item xs={12}><FormControl fullWidth className={classes.child_root} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-amount">Sandbox API Key</InputLabel>
                    <OutlinedInput
                      id="sandboxKey"
                      type={values[Keys.Name] ? 'text' : 'password'}
                      value={Keys.pk}
                      endAdornment={
                        <InputAdornment position="end">
                          {OutboundSand2 ? "" : <Tooltip title="Add">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={props.handleAdd.bind(null,Keys, 'OutboundSandbox2')} 
                              //onClick={() => { handleAdd(Keys, 'InboundSandbox2') }}
                              onMouseDown={handleMouseDownPassword}
                            >
                              <AddCircleIcon fontSize="small" />

                            </IconButton></Tooltip>}
                          <Tooltip title="View">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => { handleClickShowPassword(Keys.Name) }}
                              onMouseDown={handleMouseDownPassword}
                            >
                              <Visibility fontSize="small" />

                            </IconButton></Tooltip>
                          <Tooltip title="Copy">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => { handleClickCopyKey(Keys.Name, Keys.pk) }}
                              onMouseDown={handleMouseDownPassword}
                            >
                              <FileCopyOutlinedIcon fontSize="small" />
                            </IconButton></Tooltip>
                          <Tooltip title="Rotate">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={props.handleRotate.bind(null,Keys, 'OutboundSandbox2')}  
                              onMouseDown={handleMouseDownPassword}
                            >
                              <RotateLeftIcon fontSize="small" />

                            </IconButton></Tooltip>
                            {Keys.Name=="OutboundSandbox2"?
                          <Tooltip title="Delete">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={props.handleDelete.bind(null,Keys, 'OutboundSandbox2')} 
                               onMouseDown={handleMouseDownPassword}
                            >
                              <DeleteIcon fontSize="small" />

                            </IconButton></Tooltip>:""}
                        </InputAdornment>
                      }
                      labelWidth={125}
                    />
                  </FormControl></Grid>) : null
            })}
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

Outbount.propTypes = {
  className: PropTypes.string,
  handleAdd:PropTypes.func,
  handleDelete:PropTypes.func,
  handleRotate:PropTypes.func
};

export default Outbount;
